import {handleAjaxError} from "../handle_error";

var Magazine = {
  init: function () {
    Magazine.triggerUploadImgMainImgBtn();
    Magazine.handleChangePictureMainImage();
    Magazine.triggerUploadImgThumbImgBtn();
    Magazine.handleChangePictureThumbImage();
    Magazine.triggerUploadImgStoreInfoImgBtn();
    Magazine.handleChangePictureStoreInfoImage();
    Magazine.triggerUploadImgMetaImgBtn();
    Magazine.handleChangePictureMetaImage();
    Magazine.selectOnlyOneCheckbox()
    Magazine.handleSubmit()
    Magazine.handleSort()
    Magazine.changeStatus()
    Magazine.changeFilter()
    Magazine.sort()
  },

  triggerUploadImgMainImgBtn: function () {
    Magazine.triggerUploadImgBtn(
      $('#upload-main-image'),
      $('#main-image-input-field')
    )
  },

  handleChangePictureMainImage: function () {
    Magazine.handleChangePicture($('#main-image-input-field'))
  },

  triggerUploadImgThumbImgBtn: function () {
    Magazine.triggerUploadImgBtn(
      $('#upload-eye-catch-image'),
      $('#eye-catch-image-input-field')
    )
  },

  handleChangePictureThumbImage: function () {
    Magazine.handleChangePicture($('#eye-catch-image-input-field'))
  },

  triggerUploadImgStoreInfoImgBtn: function () {
    Magazine.triggerUploadImgBtn(
      $('#upload-store-info-image'),
      $('#store-info-image-input-field')
    )
  },

  handleChangePictureStoreInfoImage: function () {
    Magazine.handleChangePicture($('#store-info-image-input-field'))
  },

  triggerUploadImgMetaImgBtn: function () {
    Magazine.triggerUploadImgBtn(
      $('#upload-meta-image'),
      $('#meta-image-input-field')
    )
  },

  handleChangePictureMetaImage: function () {
    Magazine.handleChangePictureWithRemoveBtn($('#meta-image-input-field'));
    Magazine.handleRemoveImg($('#remove-meta-image-btn'));
  },

  handleChangePictureWithRemoveBtn: function (elInput) {
    elInput.change(function(event) {
      if (event.target.files.length > 0) {
        let dataId = event.target.getAttribute('data-id');
        let output = document.getElementById(dataId);
        let removeBtn = document.getElementById(`remove-${dataId}-btn`);
        let removeField = document.getElementById(`remove-${dataId}`);
        output.src = URL.createObjectURL(event.target.files[0]);
        output.style.display = 'block';
        removeBtn.style.display = 'inline-block';
        removeField.value = false;
      }
    });
  },

  handleRemoveImg: function (elBtnRemove) {
    elBtnRemove.click(function(event) {
      let dataId = event.target.getAttribute('data-id');
      let output = document.getElementById(dataId);
      let removeField = document.getElementById(`remove-${dataId}`);
      let fileField = document.getElementById(`${dataId}-input-field`);
      let fileCache = document.getElementById(`${dataId}-cache`);
      removeField.value = true;
      event.target.style.display = 'none';
      output.style.display = 'none';
      fileField.value = '';
      fileCache.value = '';
      output.src = '';
    });
  },

  triggerUploadImgBtn: function (elBtnUpload, elInput) {
    elBtnUpload.click(function() {
      elInput.trigger('click');
    });
  },

  handleChangePicture: function (elInput) {
    elInput.change(function(event) {
      if (event.target.files.length > 0) {
        let dataId = event.target.getAttribute('data-id');
        let output = document.getElementById(dataId);
        output.src = URL.createObjectURL(event.target.files[0]);
        output.style.display = 'block';
      }
    });
  },

  selectOnlyOneCheckbox: function () {
    $('.form-magazine').find('.ck-catagory').on('click', function() {
      const $box = $(this);
      if ($box.is(":checked")) {
        const group = "input:checkbox[name='" + $box.attr("name") + "']";
        $(group).prop("checked", false);
        $box.prop("checked", true);
      } else {
        $box.prop("checked", false);
      }
    });
  },

  handleSubmit: function () {
    $('.magazine-submit-preview').click(function () {
      let actionMethod = $('#action_method').val()
      if (actionMethod !== 'put') {
        $('input[name="magazine[status]"]').val('drafted')
      }
      $('#submit_action').val('save-preview')
    })
  },

  handleSort: function () {
    $(document).on('submit', '#form-magazine', function(event) {
      $('.locations-wrapper').each(function() {
        let sorted = $('.sortable-blocks').sortable('toArray');
        jQuery.each(sorted, function(i, val) {
          $('#' + val).find('input.order-field').val(i + 1);
        });
      });
    });
  },

  changeStatus: function() {
    $('.magazine-status')
      .on('focus', function () {
        $(".btn-confirm-status-magazine").attr('data-status-old', $(this).val())
      })
      .on('change', function () {
        $(".btn-confirm-status-magazine").attr('data-url', $(this).parent('.select').data('url'))
        $(".btn-confirm-status-magazine").attr('data-status', $(this).val())
        $(".btn-confirm-status-magazine").attr('data-id', $(this).parent('.select').attr('id'))
        $(this).val($(".btn-confirm-status-magazine").attr('data-status-old'));
        $("#modal_magazine_change_status").modal('show')
      })

    $(".btn-confirm-status-magazine").on('click', function(){
      const status = $(this).attr('data-status')
      const url = $(this).attr('data-url')
      $.ajax({
        url: url,
        data: { status: status },
        method: 'POST',
        success: function(response) {
          if(response.result) {
            let id = $(".btn-confirm-status-magazine").attr('data-id');
            let select = $("#"+id).find('select')
            select.val($(".btn-confirm-status-magazine").attr('data-status'));
            toastr.success('公開ステータスを更新しました。')
          } else {
            toastr.error(response.error)
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
        },
        complete: function () {
          $("#modal_magazine_change_status").modal('hide')
        }
      })
    });
  },

  changeFilter: function () {
    $('#magazine_display_site').bind('change', function () {
      Magazine.searchMagazine();
    })
  },

  sort: function () {
    $('.table-magazine').on('click', '.magazine-sort', function (e) {
      const order = $(this).data('order');
      const sort_by = $(this).data('sort_by');
      $('form#magazine_search_form input#sort_magazines').val(`${sort_by} ${order}`);
      Magazine.searchMagazine()
    })
  },

  searchMagazine: function () {
    $('form#magazine_search_form input#magazine_search_btn').trigger('click');
  },

}

export default Magazine;
