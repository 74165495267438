import { handleAjaxError } from '../handle_error';
import { onlyNumber } from '../utils';
import { disableWheelNumberField } from '../utils';
import { counterInputText } from '../utils';

const validateDataMessage = '価格と面積は0以上の数値を入力してください。';
const validateDataMessageMultiple = '多区画情報の価格と面積 ㎡は0以上を入力してください」';
const validateLandNumberMessage = '号地は0以上の数値を入力してください。'
const maxRowMessage = '作成できるのは100号地までになります。';

var SaleBukken = {
  init: function () {
    if($('#partition_sortable').length){
      SaleBukken.initPartitionSortable();
      SaleBukken.savePartitionOrder();
    }

    // MEMO: sale_bukken.js は物件一覧画面の一部(絞り込み等の部品部分)・登録・更新画面でしか、発火しないようにする
    if (! $('#bukken-section').length) {
      return
    }
    let formOriginalData = $('.bukken-form').serializeArray();

    SaleBukken.validate(formOriginalData);
    SaleBukken.save();
    SaleBukken.changeAreas();
    SaleBukken.changeFukuokaAreas();
    SaleBukken.changeLine();
    SaleBukken.changeLineEtc1();
    SaleBukken.changeFukuokaLine();
    SaleBukken.changeLandArea();
    SaleBukken.changeFukuokaLandArea();
    SaleBukken.changePrivateRoadArea();
    SaleBukken.changeFukuokaPrivateRoadArea();
    SaleBukken.changeBuildingArea();
    SaleBukken.changeFukuokaBuildingArea();
    SaleBukken.changeMonopolyArea();
    SaleBukken.changeDevelopmentArea();
    SaleBukken.changeFukuokaDevelopmentArea();
    SaleBukken.changeBalconyArea();
    SaleBukken.changeAddressCity();
    SaleBukken.changeFukuokaAddressCity();
    SaleBukken.changeImmediateDeliveryDate();
    SaleBukken.calcTuboPrice();
    SaleBukken.changePrice();
    SaleBukken.changeFukuokaPrice();
    SaleBukken.updateMapAddress();
    SaleBukken.updateFukuokaMapAddress();
    SaleBukken.initSelect2();
    SaleBukken.changeFukuokaCity();
    SaleBukken.changeTown();
    SaleBukken.changeFukuokaTown();
    SaleBukken.updateManualSchool();
    SaleBukken.updateFukuokaManualSchool();
    SaleBukken.changeAreaFloor();
    SaleBukken.changeFactsheetBottomPic();
    SaleBukken.changeTochiLandArea();
    SaleBukken.changeFukuokaTochiLandArea();
    SaleBukken.changeKubun();
    SaleBukken.changePartition();
    SaleBukken.changeFukuokaKubun();
    SaleBukken.changeAreaPerResidence();
    SaleBukken.changeAreaPerResidenceUpper();
    SaleBukken.initFactsheetTooltip();
    SaleBukken.landAreaUnitPricesPopup();
    SaleBukken.multiPartition();
    SaleBukken.initCopyButton();

    if ($('#sale_page_sort').length) {
      SaleBukken.initiateSortIcon();
      SaleBukken.updateBukkenOrder();
      SaleBukken.resetSort();
    }

    if($('.factsheet-tab2').length){
      SaleBukken.factSheetTypesInit();
      SaleBukken.handleFactSheetType();
    }
  },

  updateManualSchool: function () {
    $("#bukken_junior_high, #bukken_primary_school").parents('.item').hide();
    if ($("#manual_primary_school:checked").length) {
      $("#bukken_primary_school").parents('.item').show();
    }
    if ($("#manual_junior_high_school:checked").length) {
      $("#bukken_junior_high").parents('.item').show();
    }

    $("#manual_primary_school").change(function() {
      if(this.checked) {
        $("#bukken_primary_school").parents('.item').show();
      } else {
        $("#bukken_primary_school").val('')
        $("#bukken_primary_school").parents('.item').hide();
      }
    });

    $("#manual_junior_high_school").change(function() {
      if(this.checked) {
        $("#bukken_junior_high").parents('.item').show();
      } else {
        $("#bukken_junior_high").val('')
        $("#bukken_junior_high").parents('.item').hide();
      }
    });
  },

  updateFukuokaManualSchool: function () {
    $("#fukuoka_bukken_junior_high, #fukuoka_bukken_primary_school").parents('.item').hide();
    if ($("#fukuoka_manual_primary_school:checked").length) {
      $("#fukuoka_bukken_primary_school").parents('.item').show();
    }
    if ($("#fukuoka_manual_junior_high_school:checked").length) {
      $("#fukuoka_bukken_junior_high").parents('.item').show();
    }

    $("#fukuoka_manual_primary_school").change(function() {
      if(this.checked) {
        $("#fukuoka_bukken_primary_school").parents('.item').show();
      } else {
        $("#fukuoka_bukken_primary_school").val('')
        $("#fukuoka_bukken_primary_school").parents('.item').hide();
      }
    });

    $("#fukuoka_manual_junior_high_school").change(function() {
      if(this.checked) {
        $("#fukuoka_bukken_junior_high").parents('.item').show();
      } else {
        $("#fukuoka_bukken_junior_high").val('')
        $("#fukuoka_bukken_junior_high").parents('.item').hide();
      }
    });
  },

  initSelect2: function () {
    $('.js-searchable').select2();
    $('.js-searchable-width300').select2({ width: '300px'});
    $('.js-select2-town-placeholer').one('select2:open', function(e) {
      $('input.select2-search__field').prop('placeholder', 'キーワードを入力してください');
    });
  },

  validate: function (formOriginalData) {
    $('.save-bukken').attr('disabled', 'disabled');
    $('.validate-bukken').bind('click', function (e) {
      $('.bukken-error-message').html('')
      $('.save-bukken').addClass('wait');
      var url = $(e.currentTarget).data('validate-url')
      var $form = $('.bukken-form')
      var parameters = new FormData($form[0]);
      var method = 'POST'
      $.ajax({
        url: url,
        data: parameters,
        method: method,
        processData: false, // FormData
        contentType: false, // FormData
        success: function (response) {
          if ($("#manual_primary_school:checked").length && !$("#bukken_primary_school").val()) {
            response.errors.push("他の小学校区を入力してください")
          }
          if ($("#manual_junior_high_school:checked").length && !$("#bukken_junior_high").val()) {
            response.errors.push("他の中学校区を入力してください")
          }
          if ($("#fukuoka_manual_primary_school:checked").length && !$("#fukuoka_bukken_primary_school").val()) {
            response.errors.push("他の小学校区を入力してください")
          }
          if ($("#fukuoka_manual_junior_high_school:checked").length && !$("#fukuoka_bukken_junior_high").val()) {
            response.errors.push("他の中学校区を入力してください")
          }
          if (response.used_type_warning) {
            $('.used-type-warning-message').show();
          } else {
            $('.used-type-warning-message').hide();
          }
          if (response.errors.length != 0) {
            $('.bukken-error-message').html(response.errors.join('<br>'));
            $('.bukken-error-message').show();
            $('.save-bukken').attr('disabled', 'disabled');
          } else {
            let modelName = $('input#model_name').val();
            let selectedBukkenKubun = $(`#${modelName}_kubun`).val();
            let currentBukkenKubun = $(`#${modelName}_kubun`).data('current-value');
            let methodName = $('input[name="_method"]').val();
            let formDataWithoutKubun = $.map($('.bukken-form').serializeArray(), function(val, _i) {
              if (val['name'] != 'bukken[kubun]' && val['name'] != 'bukken[status]') {
                return val;
              }
            });
            let formOriginalDataWithoutKubun = $.map(formOriginalData, function(val, _i) {
              if (val['name'] != 'bukken[kubun]' && val['name'] != 'bukken[status]') {
                return val;
              }
            });

            if (methodName == 'patch' && selectedBukkenKubun == '0' && currentBukkenKubun != 0
              && JSON.stringify(formDataWithoutKubun) != JSON.stringify(formOriginalDataWithoutKubun)) {
              $('.bukken-error-message').text('物件区分：下書きへの変更時は他の項目は変更できません');
              $('.bukken-error-message').show();
              $('.save-bukken').attr('disabled', 'disabled');
            } else {
              $('.bukken-error-message').hide();
              $('.save-bukken').removeAttr('disabled');
            }
          }
        },
        error: function (request, status, error) {
          $(this).prop('disabled', false);
        },
        complete: function(request, status) {
          $('.save-bukken').removeClass('wait');
        }
      });
    });
  },
  save: function () {
    $('.save-bukken').bind('click', function (e) {
      $('.save-bukken').prop('disabled', true)
      $('.save-bukken').addClass('wait')
      var $form = $('.bukken-form')
      //var parameters = $form.serialize();
      var parameters = new FormData($form[0]);
      var id = $form.find('.bukken-id').val();
      var isPersisted = $form.find('.bukken-table-id').val();
      var link = $form.attr('action')
      var method = 'POST'
      if (isPersisted) {
        if (link.includes('?')) {
          let link_parts = link.split('?');
          link = `${link_parts[0]}/${id}?${link_parts[1]}`
        } else {
          link = `${link}/${id}`;
        }
        method = 'PUT'
      }
      $.ajax({
        url: link,
        data: parameters,
        method: method,
        processData: false, // FormData
        contentType: false, // FormData
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          } else {
            $('.bukken-error-message').html(response.errors.join('<br>'));
            $('.bukken-error-message').show();
            $('.save-bukken').prop('disabled', false);
            $('.save-bukken').removeClass('wait')
          }
        },
        error: function (request, status, error) {
          handleAjaxError(request, status, error);
          $('.save-bukken').prop('disabled', false);
          $('.save-bukken').removeClass('wait')
        }
      });
    });
  },

  changeAreas: function() {
    $('#BukkenAreasInput').bind('change', function (e) {
      const areas_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/house/search_section',
        data: { areas_id: areas_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
          $(this).prop('disabled', false);
        },
      });
    })
  },

  changeFukuokaCity: function() {
    $('#FukuokaBukkenCityInput').bind('change', function (event) {
      const city_id = $(event.currentTarget).val()
      $('.modal-loading').modal('show')
      $.ajax({
        url: '/kanri/sales/fuk/house/search_towns_by_city_id',
        data: { city_id: city_id },
        method: 'get',
        success: function (response) {
          $('#FukuokaBukkenPrimarySchoolInput').val('')
          $('#FukuokaBukkenJuniorHighSchoolInput').val('')
          $('.modal-loading').modal('hide');
          $(this).prop('disabled', false);
        },
      });
    })
  },

  changeTown: function() {
    $('#BukkenTownInput').bind('change', function (event) {
      const town_id = $(event.currentTarget).val()
      $('.modal-loading').modal('show')
      $.ajax({
        url: '/kanri/sales/house/search_school',
        data: { town_id: town_id },
        method: 'get',
        success: function (response) {
          $('.modal-loading').modal('hide');
          $(this).prop('disabled', false);
        },
      });
    })
  },

  changeFukuokaTown: function() {
    $('#FukuokaBukkenTownInput').bind('change', function (event) {
      const town_id = $(event.currentTarget).val()
      $('.modal-loading').modal('show')
      $.ajax({
        url: '/kanri/sales/fuk/house/search_school',
        data: { town_id: town_id },
        method: 'get',
        success: function (response) {
          $('.modal-loading').modal('hide');
          $(this).prop('disabled', false);
        },
      });
    })
  },

  changeFukuokaAreas: function() {
    $('#FukuokaBukkenAreasInput').bind('change', function (e) {
      const areas_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/fuk/base/search_section',
        data: { areas_id: areas_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
          $(this).prop('disabled', false);
        },
      });
    })
  },

  changeLine: function() {
    $('#BukkenLineInput').bind('change', function (e) {
      const line_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/house/search_station',
        data: { line_id: line_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
        },
      });
    })
  },
  changeLineEtc1: function() {
    $('#BukkenLineEtc1Input').bind('change', function (e) {
      const line_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/house/search_station_etc1',
        data: { line_id: line_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
        },
      });
    })
  },
  changeFukuokaLine: function() {
    $('#FukuokaBukkenLineInput').bind('change', function (e) {
      const line_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/fuk/base/search_station',
        data: { line_id: line_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
        },
      });
    })
  },
  changeLandArea: function () {
    $('#BukkenPriceInput, #BukkenLandAreaInput').bind('change', function (e) {
      const price = $('#BukkenPriceInput').val()
      const land_area = $('#BukkenLandAreaInput').val()
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_price) {
          $('#BukkenTuboPrice').text(response.tubo_price + '万円')
        }
        if (response.tubo_area) {
          $('#BukkenTuboLandArea').text(response.tubo_area + '坪')
        }
      })

    })
  },
  changeFukuokaLandArea: function () {
    $('#FukuokaBukkenPriceInput, #FukuokaBukkenLandAreaInput').bind('change', function (e) {
      const price = $('#FukuokaBukkenPriceInput').val()
      const land_area = $('#FukuokaBukkenLandAreaInput').val()
      SaleBukken.calcFukuokaTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_price) {
          $('#FukuokaBukkenTuboPrice').text(response.tubo_price + '万円')
        }
        if (response.tubo_area) {
          $('#FukuokaBukkenTuboLandArea').text(response.tubo_area + '坪')
        }
      })

    })
  },
  changePrivateRoadArea: function () {
    $('#BukkenPrivateRoadAreaInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#BukkenPrivateRoadAreaInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenTuboPrivateRoadArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeFukuokaPrivateRoadArea: function () {
    $('#FukuokaBukkenPrivateRoadAreaInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#FukuokaBukkenPrivateRoadAreaInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#FukuokaBukkenTuboPrivateRoadArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeBuildingArea: function () {
    $('#BukkenBuildingAreaInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#BukkenBuildingAreaInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenTuboBuildingArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeFukuokaBuildingArea: function () {
    $('#FukuokaBukkenBuildingAreaInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#FukuokaBukkenBuildingAreaInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#FukuokaBukkenTuboBuildingArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeMonopolyArea: function () {
    $('#BukkenMonopolyAreaInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#BukkenMonopolyAreaInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenTuboMonopolyArea').text(response.tubo_area + '坪')
        }
      })
    })
  },

  changeAreaPerResidence: function () {
    $('#BukkenAreaPerResidenceInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#BukkenAreaPerResidenceInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenAreaPerResidence').text(response.tubo_area + '坪')
        }
      })
    })
  },

  changeAreaPerResidenceUpper: function () {
    $('#BukkenAreaPerResidenceUpperInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#BukkenAreaPerResidenceUpperInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenAreaPerResidenceUpper').text(' ～ ' + response.tubo_area + '坪')
        }
      })
    })
  },
  changeDevelopmentArea: function () {
    $('#BukkenDevelopmentAreaInput').bind('change', function (e) {
      const price = null;
      const development_area = $('#BukkenDevelopmentAreaInput').val();
      SaleBukken.calcTuboPrice(price, development_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenTuboDevelopmentArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeFukuokaDevelopmentArea: function () {
    $('#FukuokaBukkenDevelopmentAreaInput').bind('change', function (e) {
      const price = null;
      const development_area = $('#FukuokaBukkenDevelopmentAreaInput').val();
      SaleBukken.calcTuboPrice(price, development_area).done(function(response) {
        if (response.tubo_area) {
          $('#FukuokaBukkenTuboDevelopmentArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeBalconyArea: function () {
    $('#BukkenBalconyAreaInput').bind('change', function (e) {
      const price = null;
      const land_area = $('#BukkenBalconyAreaInput').val();
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        if (response.tubo_area) {
          $('#BukkenTuboBalconyArea').text(response.tubo_area + '坪')
        }
      })
    })
  },
  changeAddressCity: function() {
    $('#BukkenAddressCityInput').bind('change', function (e) {
      const address_city_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/house/search_address_town',
        data: { address_city_id: address_city_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
        },
      });
    })
  },
  changeFukuokaAddressCity: function() {
    $('#FukuokaBukkenAddressCityInput').bind('change', function (e) {
      const address_city_id = $(e.currentTarget).val()
      $.ajax({
        url: '/kanri/sales/fuk/base/search_address_town',
        data: { address_city_id: address_city_id },
        method: 'get',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          }
        },
      });
    })
  },

  changeImmediateDeliveryDate: function() {
    $('#ImmediateDeliveryDateCheckbox').bind('change', function (e) {
      if (this.checked) {
        $('#DeliveryDateInput').val('').prop('disabled', true);
      } else {
        $('#DeliveryDateInput').prop('disabled', false);
      }
    })
  },

  calcTuboPrice: function (price, land_area) {
    return $.ajax({
      url: '/kanri/sales/base/calc_tubo_price',
      data: { price: price, land_area: land_area },
      method: 'get'
    });
  },
  calcFukuokaTuboPrice: function (price, land_area) {
    return $.ajax({
      url: '/kanri/sales/fuk/base/calc_tubo_price',
      data: {price: price, land_area: land_area},
      method: 'get'
    });
  },
  changePrice: function () {
    $('#BukkenPriceInput, #BukkenExpectedIncomeInput').bind('change', function (e) {
      const price = $('#BukkenPriceInput').val()
      const expected_income = $('#BukkenExpectedIncomeInput').val()
      const coupon_yield = (parseFloat(expected_income) / parseFloat(price)) * 100
      $('#BukkenCouponYieldInput').val(Math.round(coupon_yield * 100) / 100)
    })
  },
  changeFukuokaPrice: function () {
    $('#FukuokaBukkenPriceInput, #FukuokaBukkenExpectedIncomeInput').bind('change', function (e) {
      const price = $('#FukuokaBukkenPriceInput').val()
      const expected_income = $('#FukuokaBukkenExpectedIncomeInput').val()
      const coupon_yield = (parseFloat(expected_income) / parseFloat(price)) * 100
      $('#FukuokaBukkenCouponYieldInput').val(Math.round(coupon_yield * 100) / 100)
    })
  },
  updateMapAddress: function () {
    $('#search_map_link').bind('click', function (e) {
      const tab = new URL(window.location.href).searchParams.get("tab");
      if (tab == 'new_house') {
        // 新築戸建のみ、bukken_address ではなく athome所在地（番地）を反映する
        const city = $('[id=BukkenAddressCityInput] option:selected').text().replace(/^--$/, '');
        const town = $('[id=BukkenAddressTownInput] option:selected').text().replace(/^--$/, '');
        var address = city + town + $('#bukken_address_banchi').val()
      } else {
        var address = $('#bukken_address').val()
      }
      $('#map-address').val(address)
    })
  },
  updateFukuokaMapAddress: function () {
    $('#fukuoka_search_map_link').bind('click', function (e) {
      const address = $('#fukuoka_bukken_address').val()
      $('#map-address').val(address)
    })
  },
  changeAreaFloor: function () {
    $('.js-area-floor').bind('change', function () {
      const price = null;
      const land_area = $(this).val();
      const currentInputId = $(this).prop('id');
      SaleBukken.calcTuboPrice(price, land_area).done(function(response) {
        // add tubo_area value to tubo input
        if (response.tubo_area) {
          $(`#${currentInputId}_tubo`).val(response.tubo_area);
        }
      })
    })
  },
  changeFactsheetBottomPic: function () {
    $('.js-factsheet-bottom-pic select').bind('change', function () {
      const imageShowTargetIndex = $('option:selected', this).attr('data-img-index-target');
      $('.js-factsheet-bottom-pic img').each(function(index) {
        $(this).toggleClass('d-none', index != imageShowTargetIndex);
      })
    })
  },
  changeTochiLandArea: function () {
    $('#tochi-price-upper-input, #tochi-land-area-upper-input').bind('change', function (e) {
      const priceUpper = $('#tochi-price-upper-input').val();
      const landAreaUpper = $('#tochi-land-area-upper-input').val();
      SaleBukken.calcTuboPrice(priceUpper, landAreaUpper).done(function(response) {
        if (response.tubo_price) {
          $('#tochi-tubo-price').text(' ～ ' + response.tubo_price + '万円')
        }
        if (response.tubo_area) {
          $('#tochi-tubo-land-area').text(' ～ ' + response.tubo_area + '坪')
        }
      })

    })
  },
  changeFukuokaTochiLandArea: function () {
    $('#fukuoka-tochi-price-upper-input, #fukuoka-tochi-land-area-upper-input').bind('change', function (e) {
      const priceUpper = $('#fukuoka-tochi-price-upper-input').val();
      const landAreaUpper = $('#fukuoka-tochi-land-area-upper-input').val();
      SaleBukken.calcTuboPrice(priceUpper, landAreaUpper).done(function(response) {
        if (response.tubo_price) {
          $('#fukuoka-tochi-tubo-price').text(' ～ ' + response.tubo_price + '万円')
        }
        if (response.tubo_area) {
          $('#fukuoka-tochi-tubo-land-area').text(' ～ ' + response.tubo_area + '坪')
        }
      })

    })
  },
  changeKubun: function () {
    $('#bukken_kubun').bind('change', function (e) {
      // 客付可物件
      if ($('#bukken_kubun').val() == 1) {
        $('#bukken_status').attr('disabled', false);
        // HP掲載不可がセットされている場合、クリア
        if ($('#bukken_status').val() == 7) {
          $('#bukken_status').val('');
        }
        // HP掲載不可の選択肢が存在する場合、削除
        if ($('#bukken_status').find('option[value="7"]').length != 0){
          $('#bukken_status').find('option[value="7"]').remove();
        }
      // 客付不可物件
      } else if ($('#bukken_kubun').val() == 4) {
        $('#bukken_status').attr('disabled', true);
        // HP掲載不可の選択肢が存在しない場合、追加
        if ($('#bukken_status').find('option[value="7"]').length == 0){
          $('#bukken_status').append('<option value="7">HP掲載不可</option></select>');
        }
        // HP掲載不可をセット
        $('#bukken_status').val(7);
      } else {
        $('#bukken_status').attr('disabled', true);
        $('#bukken_status').val('');
      }
    })
  },
  changeFukuokaKubun: function () {
    $('#fukuoka_bukken_kubun').bind('change', function (e) {
      // 客付可物件
      if ($('#fukuoka_bukken_kubun').val() == 1) {
        $('#fukuoka_bukken_status').attr('disabled', false);
        // HP掲載不可がセットされている場合、クリア
        if ($('#fukuoka_bukken_status').val() == 7) {
          $('#fukuoka_bukken_status').val('');
        }
        // HP掲載不可の選択肢が存在する場合、削除
        if ($('#fukuoka_bukken_status').find('option[value="7"]').length != 0){
          $('#fukuoka_bukken_status').find('option[value="7"]').remove();
        }
      // 客付不可物件
      } else if ($('#fukuoka_bukken_kubun').val() == 4) {
        $('#fukuoka_bukken_status').attr('disabled', true);
        // HP掲載不可の選択肢が存在しない場合、追加
        if ($('#fukuoka_bukken_status').find('option[value="7"]').length == 0){
          $('#fukuoka_bukken_status').append('<option value="7">HP掲載不可</option></select>');
        }
        // HP掲載不可をセット
        $('#fukuoka_bukken_status').val(7);
      } else {
        $('#fukuoka_bukken_status').attr('disabled', true);
        $('#fukuoka_bukken_status').val('');
      }
    })
  },
  changePartition: function () {
    $('.BukkenZoning').bind('change', function (e) {

      if ($(this).val() == 'single_partition') {
        // Enable related fields
        $('.disable-multiple-partition').attr('readonly', false).val('');
        $('a.disable-multiple-partition').removeClass('disable-href');
        $('.hide-multiple-partition').addClass('d-none');
        // Fill related fields
        $('.popup-container').css({'opacity': '0', 'display': 'block'});
        $('#bukken-land-area-unit-prices-get-max-min-btn').trigger('click');
        $('.popup-container').promise().done(function(){
          $('.popup-container').removeAttr('style');
        });
        $('.custom-popup .error-message').text('');
      } else {
        // Disable related fields
        $('.disable-multiple-partition').attr('readonly', true).val('');
        $('a.disable-multiple-partition').addClass('disable-href');
        $('.hide-multiple-partition').removeClass('d-none');
        // Fill related fields
        $('#tab-multiple-partition').css({'opacity': '0', 'display': 'block'});
        $('#multiple-partition-get-max-min-btn').trigger('click');
        $('#tab-multiple-partition').removeAttr("style");
        $('#tab-multiple-partition .error-message').text('');
      }
      $('input.bukken-land-area-unit-prices-land-number').css('border', 'solid 1px #cccccc');
      $('input.bukken-land-area-unit-prices-price').css('border', 'solid 1px #cccccc');
      $('input.bukken-land-area-unit-prices-square-meter').css('border', 'solid 1px #cccccc');
    })
  },

  initCopyButton: function() {
    $('#copy-multiple-partition-btn').bind('click', function (e) {
      let text = '';
      let listContentElements = $('#multiple-partitions-content').find('.nested-fields:visible')

      for (let item of listContentElements) {
        let landNumber = $(item).find('.bukken-land-area-unit-prices-land-number').val();
        let price = $(item).find('.bukken-land-area-unit-prices-price').val();
        let squareMeter = $(item).find('.bukken-land-area-unit-prices-square-meter').val();

        if ($(item).find('.bukken-land-area-unit-prices-land-disabled')[0].checked) {
          text += `${landNumber}号地：成約\n`;
        } else {
          text += `${landNumber}号地：${price}万円　${squareMeter}㎡ \n`;
        }
      }
      navigator.clipboard.writeText(text)
        .then(() => {
          toastr.success("コピーしました。");
        })
        .catch(() => {
          toastr.error("エラーが発生します。");
        });
      return false;
    })
  },

  initFactsheetTooltip: function () {
    $('.factsheet-tooltip').mouseover(function(e) {
      $('.factsheet-tooltip .content').fadeIn();
    })
    $('.factsheet-tooltip').mouseleave(function(e) {
      // 0.1s後にマウスを離してたらfadeOut
      setTimeout(function(){
        if ($('.factsheet-tooltip:hover').length == 0) {
          $('.factsheet-tooltip .content').fadeOut();
        }
      }, 100);
    })
  },

  loadDataFromLandAreaUnitPricesPopupToJson: function() {
    // Remove old jsonInputElement if have
    $('#json-popup-recovery').remove();

    // init jsonInputElement to save data of popup
    let jsonInputElement = document.createElement('input');
    jsonInputElement.setAttribute('type', 'hidden');
    jsonInputElement.setAttribute('id', 'json-popup-recovery');

    let value = {
      row: [],
    };


    // Find and save all rows
    let listRowElement = $('#bukken-land-area-unit-prices-content').find('.nested-fields');
    let listRowItem = [];
    for (let i = 0; i < listRowElement.length; i++) {
      let idElement = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-id')[0];
      let disabledCheckbox = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-land-disabled')[0];
      let landNumberElement = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-land-number')[0];
      let priceElement = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-price')[0];
      let squareMeterElement = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-square-meter')[0];
      let tuboElement = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-tubo')[0];
      let pricePerTuboElement = listRowElement[i].getElementsByClassName('bukken-land-area-unit-prices-price-per-tubo')[0];
      let destroyElement = listRowElement[i].querySelector('#destroy-wrapper').querySelector('input[type=hidden]');
      let style = listRowElement[i].style.cssText;

      let rowItem = {
        id:{
          name: idElement.name,
          value: idElement.value
        },
        disabled: {
          name: disabledCheckbox.name,
          value: disabledCheckbox.checked,
        },
        landNumber: {
          name: landNumberElement.name,
          value: landNumberElement.value,
        },
        price: {
          name: priceElement.name,
          value: priceElement.value,
        },
        squareMeter: {
          name: squareMeterElement.name,
          value: squareMeterElement.value,
        },
        tubo: {
          name: tuboElement.name,
          value: tuboElement.value,
        },
        pricePerTubo: {
          name: pricePerTuboElement.name,
          value: pricePerTuboElement.value,
        },
        destroy: {
          name: destroyElement.name,
          value: destroyElement.value,
          id: destroyElement.id,
        },
        style: style,
      };
      listRowItem.push(rowItem);
    }
    value['row'] = listRowItem;

    jsonInputElement.setAttribute('value', JSON.stringify(value));

    document.getElementById('json-popup-recovery-container').appendChild(jsonInputElement);
  },
  loadDataFromJsonToLandAreaUnitPricesPopup: function() {
    $('#bukken-land-area-unit-prices-content').find('.nested-fields').remove();

    let jsonObject = JSON.parse($('#json-popup-recovery').val());


    // Add row field to popup
    let listRow = jsonObject.row;
    for (let i = listRow.length - 1; i >= 0; i--) {
      // Build id
      let hiddenIdInput = document.createElement('input');
      hiddenIdInput.setAttribute('type', 'hidden');
      hiddenIdInput.setAttribute('value', listRow[i].id.value);
      hiddenIdInput.setAttribute('name', listRow[i].id.name);
      hiddenIdInput.classList.add('bukken-land-area-unit-prices-id');

      // Build disabled
      let hiddenCheckboxInput = document.createElement('input');
      hiddenCheckboxInput.setAttribute('value', '0');
      hiddenCheckboxInput.setAttribute('type', 'hidden');
      hiddenCheckboxInput.setAttribute('name', listRow[i].disabled.name);
      let disabledCheckboxInput = document.createElement('input');
      disabledCheckboxInput.classList.add('input-checkbox', 'bukken-land-area-unit-prices-land-disabled');
      disabledCheckboxInput.setAttribute('value', '1');
      disabledCheckboxInput.setAttribute('type', 'checkbox');
      disabledCheckboxInput.setAttribute('name', listRow[i].disabled.name);
      disabledCheckboxInput.checked = listRow[i].disabled.value;
      let checkboxIcon = document.createElement('span');
      checkboxIcon.classList.add('icon-checkbox');
      let checkboxTitle = document.createElement('span');
      checkboxTitle.classList.add('title');
      checkboxTitle.innerText = '成約';
      let disabledCheckboxLabel = document.createElement('label');
      disabledCheckboxLabel.appendChild(hiddenCheckboxInput);
      disabledCheckboxLabel.appendChild(disabledCheckboxInput);
      disabledCheckboxLabel.appendChild(checkboxIcon);
      disabledCheckboxLabel.appendChild(checkboxTitle);
      let disabledCheckboxWrap = document.createElement('div');
      disabledCheckboxWrap.classList.add('item');
      disabledCheckboxWrap.appendChild(hiddenIdInput);
      disabledCheckboxWrap.appendChild(disabledCheckboxLabel);

      // Build item 1 (landNumber)
      let landNumberElement = document.createElement('input');
      landNumberElement.classList.add('input-text', 'w65px', 'only-number', 'bukken-land-area-unit-prices-land-number', 'index');
      landNumberElement.setAttribute('value', listRow[i].landNumber.value);
      landNumberElement.setAttribute('type', 'number');
      landNumberElement.setAttribute('name', listRow[i].landNumber.name);
      if (listRow[i].disabled.value == true) {
        landNumberElement.setAttribute('readonly', true);
      }
      let item1Element = document.createElement('div');
      item1Element.classList.add('item');
      item1Element.appendChild(landNumberElement);

      // Build item 2 (号地)
      let item2Element = document.createElement('div');
      item2Element.classList.add('item');
      item2Element.innerHTML = '号地';

      // Build item 3 (&nbsp;)
      let item3Element = document.createElement('div');
      item3Element.classList.add('item');
      item3Element.innerHTML = '&nbsp;';

      // Build item 4 (price)
      let priceElement = document.createElement('input');
      priceElement.classList.add('input-text', 'w65px', 'only-number', 'bukken-land-area-unit-prices-price');
      priceElement.setAttribute('value', listRow[i].price.value);
      priceElement.setAttribute('type', 'number');
      priceElement.setAttribute('name', listRow[i].price.name);
      if (listRow[i].disabled.value == true) {
        priceElement.setAttribute('readonly', true);
      }
      let item4Element = document.createElement('div');
      item4Element.classList.add('item');
      item4Element.appendChild(priceElement);

      // Build item 5 (万円)
      let item5Element = document.createElement('div');
      item5Element.classList.add('item');
      item5Element.innerHTML = '万円';

      // Build item 6 (&nbsp;)
      let item6Element = document.createElement('div');
      item6Element.classList.add('item');
      item6Element.innerHTML = '&nbsp;';

      // Build item 7 (squareMeter)
      let squareMeterElement = document.createElement('input');
      squareMeterElement.classList.add('input-text', 'w65px', 'bukken-land-area-unit-prices-square-meter');
      squareMeterElement.setAttribute('value', listRow[i].squareMeter.value);
      squareMeterElement.setAttribute('type', 'number');
      squareMeterElement.setAttribute('name', listRow[i].squareMeter.name);
      if (listRow[i].disabled.value == true) {
        squareMeterElement.setAttribute('readonly', true);
      }
      let item7Element = document.createElement('div');
      item7Element.classList.add('item');
      item7Element.appendChild(squareMeterElement);

      // Build item 8 (&nbsp;)
      let item8Element = document.createElement('div');
      item8Element.classList.add('item');
      item8Element.innerHTML = '&nbsp;';

      // Build item 9 (tubo)
      let tuboElement = document.createElement('input');
      tuboElement.classList.add('input-text', 'w65px', 'bukken-land-area-unit-prices-tubo');
      tuboElement.setAttribute('readonly', 'readonly');
      tuboElement.setAttribute('value', listRow[i].tubo.value);
      tuboElement.setAttribute('type', 'number');
      tuboElement.setAttribute('name', listRow[i].tubo.name);
      tuboElement.setAttribute('data-disabled', listRow[i].disabled.value);
      let item9Element = document.createElement('div');
      item9Element.classList.add('item');
      item9Element.appendChild(tuboElement);

      // Build item 10 (=)
      let item10Element = document.createElement('div');
      item10Element.classList.add('item');
      item10Element.innerHTML = '=';

      // Build item 11 (pricePerTubo)
      let pricePerTuboElement = document.createElement('input');
      pricePerTuboElement.classList.add('input-text', 'w65px', 'bukken-land-area-unit-prices-price-per-tubo');
      pricePerTuboElement.setAttribute('readonly', 'readonly');
      pricePerTuboElement.setAttribute('value', listRow[i].pricePerTubo.value);
      pricePerTuboElement.setAttribute('type', 'number');
      pricePerTuboElement.setAttribute('name', listRow[i].pricePerTubo.name);
      pricePerTuboElement.setAttribute('data-disabled', listRow[i].disabled.value);
      let item11Element = document.createElement('div');
      item11Element.classList.add('item');
      item11Element.appendChild(pricePerTuboElement);

      // Build item 12 (万円)
      let item12Element = document.createElement('div');
      item12Element.classList.add('item');
      item12Element.innerHTML = '万円';

      // Build item 13 (X btn)
      let item13Element = document.createElement('a');
      item13Element.classList.add('btn', 'remove-btn', 'remove_fields', 'existing');
      item13Element.setAttribute('href', '#');
      item13Element.innerHTML = 'x';

      // Build item 14 (hidden input destroy)
      let hiddenInputDestroy = document.createElement('input');
      hiddenInputDestroy.setAttribute('type', 'hidden');
      hiddenInputDestroy.setAttribute('id', listRow[i].destroy.id);
      hiddenInputDestroy.setAttribute('name', listRow[i].destroy.name);
      hiddenInputDestroy.setAttribute('value', listRow[i].destroy.value);
      let item14Element = document.createElement('div');
      item14Element.setAttribute("id", "destroy-wrapper");
      item14Element.appendChild(hiddenInputDestroy);

      // Build item column
      let itemColumnElement = document.createElement('div');
      itemColumnElement.classList.add('item', 'column');
      itemColumnElement.appendChild(disabledCheckboxWrap);
      itemColumnElement.appendChild(item1Element);
      itemColumnElement.appendChild(item2Element);
      itemColumnElement.appendChild(item3Element);
      itemColumnElement.appendChild(item4Element);
      itemColumnElement.appendChild(item5Element);
      itemColumnElement.appendChild(item6Element);
      itemColumnElement.appendChild(item7Element);
      itemColumnElement.appendChild(item8Element);
      itemColumnElement.appendChild(item9Element);
      itemColumnElement.appendChild(item10Element);
      itemColumnElement.appendChild(item11Element);
      itemColumnElement.appendChild(item12Element);
      itemColumnElement.appendChild(item13Element);
      itemColumnElement.appendChild(item14Element);

      // Build nested fields
      let nestedFieldsElement = document.createElement('div');
      nestedFieldsElement.classList.add('nested-fields', 'mt-2');
      nestedFieldsElement.setAttribute('style', listRow[i].style);
      nestedFieldsElement.appendChild(itemColumnElement);

      document.getElementById('bukken-land-area-unit-prices-content').prepend(nestedFieldsElement);

      let onlyNumberElements = nestedFieldsElement.getElementsByClassName('only-number')
      for (let i = 0; i < onlyNumberElements.length; i++) {
        onlyNumberElements[i].addEventListener('input', function() {
          onlyNumber(this);
        });
      }
    }

    // Add round down and auto calculation for all rows
    let listRowElement = $('#bukken-land-area-unit-prices-content').find('.nested-fields');
    for (let i = 0; i < listRowElement.length; i++) {
      SaleBukken.initCalculatoringEvent(listRowElement[i], true);
      SaleBukken.initleDisable(listRowElement[i], true);
    }

    // Add hander event for x btn
    listRowElement = $('#bukken-land-area-unit-prices-content').find('.nested-fields');
    for (let i = 0; i < listRowElement.length; i++) {
      listRowElement[i].getElementsByClassName('remove-btn')[0].addEventListener('click', function() {
        if ($('#bukken-land-area-unit-prices-content').find(`#bukken_bukken_land_area_unit_prices_attributes_${i}_id`).length == 0) {
          listRowElement[i].remove();
        } else {
          let destroyFieldElement = listRowElement[i].querySelector('#destroy-wrapper').querySelector('input[type=hidden]');
          destroyFieldElement.setAttribute('value', '1');
        }
      });
    }
  },
  closeLandAreaUnitPricesPopup: function() {
    $('.custom-popup .error-message').text('');
    $('.custom-popup .popup-container').fadeOut(500);
    SaleBukken.loadDataFromJsonToLandAreaUnitPricesPopup();
  },
  roundDownSquareMeter: function(squareMeterElement) {
    let value = squareMeterElement.value;
    if (value.trim() !== '') {
      //MEMO: 丸め誤差が生じるため、指数表現の文字列に変換して計算し、単項プラス演算子で数値変換している
      value = +(Math.floor(value + "e+" + 2)  + "e-" + 2);
    } else {
      value = '';
    }
    squareMeterElement.value = value;
  },
  calcPricePerTubo: function(priceElement, tuboElement, pricePerTuboElement) {
    let priceValue = priceElement.value;
    let tuboValue = tuboElement.value;
    let pricePerTuboValue = '';
    if (priceValue.trim() !== '' && tuboValue.trim() !== '') {
      pricePerTuboValue = priceValue / tuboValue;
      //MEMO: 丸め誤差が生じるため、指数表現の文字列に変換して計算し、単項プラス演算子で数値変換している
      pricePerTuboValue = +(Math.floor(pricePerTuboValue + "e+" + 2)  + "e-" + 2);
    }
    pricePerTuboElement.value = pricePerTuboValue;
  },
  calcAreaTubo: function(squareMeterElement, tuboElement) {
    let tuboValue = '';
    let squareMeterValue = squareMeterElement.value;
    if (squareMeterValue.trim() !== '') {
      tuboValue = squareMeterValue * 0.3025;
      //MEMO: 丸め誤差が生じるため、指数表現の文字列に変換して計算し、単項プラス演算子で数値変換している
      tuboValue = +(Math.floor(tuboValue + "e+" + 2)  + "e-" + 2);
    }
    tuboElement.value = tuboValue;
    tuboElement.dispatchEvent(new Event('change'));
  },
  calcLandAreaMaxMin: function(is_single = true) {
    let listContentElements = SaleBukken.partitionElements(is_single)['content'].filter(function(_index){
      return $(this).find('.bukken-land-area-unit-prices-land-disabled')[0].checked == false;
    });

    if (listContentElements.length == 0) {
      return null;
    } else {
      let priceVals = [];
      let areaSqureMeterVals = [];
      let areaTuboVals = [];
      let pricePerTuboVals = [];

      for (let item of listContentElements) {
        priceVals.push(parseFloat($(item).find('.bukken-land-area-unit-prices-price').val()));
        areaSqureMeterVals.push(parseFloat($(item).find('.bukken-land-area-unit-prices-square-meter').val()));
        areaTuboVals.push(parseFloat($(item).find('.bukken-land-area-unit-prices-tubo').val()));
        pricePerTuboVals.push(parseFloat($(item).find('.bukken-land-area-unit-prices-price-per-tubo').val()))
      }

      let result = {
        price: [Math.min(...priceVals), Math.max(...priceVals)],
        area_square_meter: [Math.min(...areaSqureMeterVals), Math.max(...areaSqureMeterVals)],
        area_tubo: [Math.min(...areaTuboVals), Math.max(...areaTuboVals)],
        price_per_tubo: [Math.min(...pricePerTuboVals), Math.max(...pricePerTuboVals)],
      }

      return result;
    }
  },
  calcTotalAmount: function(landPriceElement, buildingExteriorTaxElement, totalAmountElement) {
    let landPriceValue = landPriceElement.value;
    let buiExtTaxValue = buildingExteriorTaxElement.value;
    let totalAmountValue = '';
    if (landPriceValue.trim() !== '' && buiExtTaxValue.trim() !== '') {
      totalAmountValue = parseFloat(landPriceValue) + parseFloat(buiExtTaxValue);
    }
    totalAmountElement.value = totalAmountValue;
  },
  validateLandAreaUnitPricesPopupNotMaxRow: function(is_single) {
    let elements = SaleBukken.partitionElements(is_single);
    if (elements['content'].length >= 100) {
      if (!elements['message'].text().includes(maxRowMessage)) {
        if (elements['message'].text().length > 0) {
          elements['message'].append('<br>');
        }
        elements['message'].append(maxRowMessage);
      }
      return false;
    }
    return true;
  },
  validateLandAreaUnitPricesPopupNotEmpty: function(is_single) {
    let elements = SaleBukken.partitionElements(is_single);
    let message = is_single ? validateDataMessage : validateDataMessageMultiple;
    if (elements['content'].length == 0) {
      if (!elements['message'].text().includes(message)) {
        if (elements['message'].text().length > 0) {
          elements['message'].append('<br>');
        }
        elements['message'].append(message);
      }
      return false;
    }
    return true;
  },
  validateLandAreaUnitPricesPopupData: function(is_single) {
    let elements = SaleBukken.partitionElements(is_single);
    let isLandNumberValid = true;
    let isDataValid = true;
    for (let i = 0; i < elements['content'].length; i++) {
      let disabledCheckbox = elements['content'][i].getElementsByClassName('bukken-land-area-unit-prices-land-disabled')[0];

      if (!disabledCheckbox.checked) {
        let landNumberElement = elements['content'][i].getElementsByClassName('bukken-land-area-unit-prices-land-number')[0];
        let priceElement = elements['content'][i].getElementsByClassName('bukken-land-area-unit-prices-price')[0];
        let squareMeterElement = elements['content'][i].getElementsByClassName('bukken-land-area-unit-prices-square-meter')[0];

        if (landNumberElement.value.trim() === '' || parseFloat(landNumberElement.value) <= 0) {
          landNumberElement.setAttribute('style', 'border: solid 1px red;');
          isLandNumberValid = false;
        } else {
          landNumberElement.setAttribute('style', 'border: solid 1px #cccccc;');
        }

        if (priceElement.value.trim() === '' || parseFloat(priceElement.value) <= 0) {
          priceElement.setAttribute('style', 'border: solid 1px red;');
          isDataValid = false;
        } else {
          priceElement.setAttribute('style', 'border: solid 1px #cccccc;');
        }

        if (squareMeterElement.value.trim() === '' || parseFloat(squareMeterElement.value) <= 0) {
          squareMeterElement.setAttribute('style', 'border: solid 1px red;');
          isDataValid = false;
        } else {
          squareMeterElement.setAttribute('style', 'border: solid 1px #cccccc;');
        }
      }
    }

    if (!isDataValid) {
      let message = is_single ? validateDataMessage : validateDataMessageMultiple;
      if (!elements['message'].text().includes(message)) {
        if (elements['message'].text().length > 0) {
          elements['message'].append('<br>');
        }
        elements['message'].append(message);
      }
    }
    if (!isLandNumberValid) {
      if (!elements['message'].text().includes(validateLandNumberMessage)) {
        if (elements['message'].text().length > 0) {
          elements['message'].append('<br>');
        }
        elements['message'].append(validateLandNumberMessage);
      }
    }
    return isDataValid && isLandNumberValid;
  },
  landAreaUnitPricesPopup: function () {
    //   f5 page => load data from popup into json input
    //   Click close popup button => load data from json input into popup
    //   Click '計算する' (get-max-min) button => load data from popup into json input
    $('#bukken-land-area-unit-prices-popup-link').click(function() {

      $('.custom-popup .popup-container').fadeIn(500);
      return false;
    });

    $('.custom-popup .close-btn').click(function() {
      SaleBukken.closeLandAreaUnitPricesPopup();
      return false;
    });

    $(document).click(function(event) {
      if ($('.custom-popup .popup-container:visible').length == 0) {
        return;
      }
      var $target = $(event.target);
      if (!$target.closest('.custom-popup .popup-content').length) {
        SaleBukken.closeLandAreaUnitPricesPopup();
      }
    });

    $('#bukken-land-area-unit-prices-content')
      .on('cocoon:before-insert', function (e, item) {
        $('.custom-popup .error-message').text('');

        let isMaxRow = !SaleBukken.validateLandAreaUnitPricesPopupNotMaxRow(true);

        let isPopupValid = SaleBukken.validateLandAreaUnitPricesPopupData(true);

        if (!isPopupValid || isMaxRow) {
          e.preventDefault();
          return;
        }

        SaleBukken.initCalculatoringEvent(item, true);
        SaleBukken.initleDisable(item, true);
      })
      .on('cocoon:after-insert', function (e, item) {
        item.find('input.only-number').on('input', function() {
          onlyNumber(this);
        })
      })

    $("#bukken-land-area-unit-prices-get-max-min-btn").click(function() {
      $('.custom-popup .error-message').text('');

      if (!SaleBukken.validateLandAreaUnitPricesPopupData(true) || !SaleBukken.validateLandAreaUnitPricesPopupNotEmpty(true)) {
        return false;
      }

      SaleBukken.loadDataFromLandAreaUnitPricesPopupToJson();

      let maxMinVal = SaleBukken.calcLandAreaMaxMin(true);
      if(maxMinVal == null) {
        var minPricePerTubo = '--';
        var maxPricePerTubo = '--';
      } else {
        var minPricePerTubo = maxMinVal['price_per_tubo'][0];
        var maxPricePerTubo = maxMinVal['price_per_tubo'][1];
      }
      $('#min-bukken-land-area-price-per-tubo').text(minPricePerTubo);
      $('#max-bukken-land-area-price-per-tubo').text(maxPricePerTubo);
      $(".custom-popup .popup-container").fadeOut(500);
      return false;
    });

    if ($('.custom-popup .popup-container').length > 0) {
      // Set round down and automatic calculation for init rows
      let listRowElement = $('#bukken-land-area-unit-prices-content').find('.nested-fields');
      for (let i = 0; i < listRowElement.length; i++) {
        SaleBukken.initCalculatoringEvent(listRowElement[i], true);
        SaleBukken.initleDisable(listRowElement[i], true);
      }

      SaleBukken.loadDataFromLandAreaUnitPricesPopupToJson();
    }
  },

  multiPartition: function () {
    let listRowElement = $('#multiple-partitions-content').find('.nested-fields');
    for (let i = 0; i < listRowElement.length; i++) {
      SaleBukken.initCalculatoringEvent(listRowElement[i], false);
      SaleBukken.initleDisable(listRowElement[i], false);
    }

    $("a.add-multiple-partitions").
      data("association-insertion-method", 'append').
      data("association-insertion-node", '#multiple-partitions-content');
    $('#multiple-partitions-content')
      .on('cocoon:before-insert', function(e, insertedItem) {
        $('#tab-multiple-partition .error-message').text('');

        let isMaxRow = !SaleBukken.validateLandAreaUnitPricesPopupNotMaxRow(false);
        let isPopupValid = SaleBukken.validateLandAreaUnitPricesPopupData(false);
        if (!isPopupValid || isMaxRow) {
          e.preventDefault();
          return;
        }

        SaleBukken.initInputEvent(insertedItem);
        SaleBukken.initCalculatoringEvent(insertedItem, false);
        SaleBukken.initleDisable(insertedItem, false);
      })
      .on('cocoon:after-insert', function (e, insertedItem) {
        counterInputText();
        disableWheelNumberField();
        ImageSale.handleModal();
        insertedItem.find('input.only-number').on('input', function() {
          onlyNumber(this);
        })
      });

    $("#multiple-partition-get-max-min-btn").click(function() {
      $('#tab-multiple-partition .error-message').text('');

      if(!$('#bukken_zoning_multiple_partition').is(':checked')) {
        $('#tab-multiple-partition .error-message').text('基本情報 / 3.価格の「区画設定」で多区画を設定してください。');
        return false;
      }

      // Validate function
      if (!SaleBukken.validateLandAreaUnitPricesPopupData(false) || !SaleBukken.validateLandAreaUnitPricesPopupNotEmpty(false)) {
        return false;
      }

      let maxMinVal = SaleBukken.calcLandAreaMaxMin(false);
      if(maxMinVal == null) {
        var minPrice = '';
        var maxPrice = '';
        var minAreaSqureMeter = '';
        var maxAreaSqureMeter = '';
        var minTubo = '';
        var maxTubo = '';
        var minPricePerTubo = '--';
        var maxPricePerTubo = '--';
      } else {
        var minPrice = maxMinVal['price'][0];
        var maxPrice = maxMinVal['price'][1];
        var minAreaSqureMeter = maxMinVal['area_square_meter'][0];
        var maxAreaSqureMeter = maxMinVal['area_square_meter'][1];
        var minTubo = maxMinVal['area_tubo'][0] + '坪';
        var maxTubo = ' ～ ' + maxMinVal['area_tubo'][1] + '坪';
        var minPricePerTubo = maxMinVal['price_per_tubo'][0];
        var maxPricePerTubo = maxMinVal['price_per_tubo'][1];
      }
      $('#BukkenPriceInput').val(minPrice);
      $('#tochi-price-upper-input').val(maxPrice);
      $('#BukkenLandAreaInput').val(minAreaSqureMeter);
      $('#tochi-land-area-upper-input').val(maxAreaSqureMeter);
      $('#BukkenTuboLandArea').text(minTubo);
      $('#tochi-tubo-land-area').text(maxTubo);
      $('#min-bukken-land-area-price-per-tubo').text(minPricePerTubo);
      $('#max-bukken-land-area-price-per-tubo').text(maxPricePerTubo);
      toastr.success('多区面情報を更新しました。');
      return false;
    });
  },

  initCalculatoringEvent: function(element, is_single) {
    let priceElement = $(element).find('.bukken-land-area-unit-prices-price')[0];
    let squareMeterElement = $(element).find('.bukken-land-area-unit-prices-square-meter')[0];
    let tuboElement = $(element).find('.bukken-land-area-unit-prices-tubo')[0];
    let pricePerTuboElement = $(element).find('.bukken-land-area-unit-prices-price-per-tubo')[0];

    squareMeterElement.addEventListener('change', function() {
      SaleBukken.roundDownSquareMeter(squareMeterElement);
      SaleBukken.calcAreaTubo(squareMeterElement, tuboElement);
      SaleBukken.calcPricePerTubo(priceElement, tuboElement, pricePerTuboElement);
    });
    priceElement.addEventListener('change', function() {
      SaleBukken.calcPricePerTubo(priceElement, tuboElement, pricePerTuboElement);
    });
    if (is_single) return;
    let landPriceElement = $(element).find('.bukken-land-area-unit-prices-land-price')[0];
    let buildingExteriorTaxElement = $(element).find('.bukken-land-area-unit-prices-building-exterior-tax')[0];
    let totalAmountElement = $(element).find('.bukken-land-area-unit-prices-total-amount')[0];
    let totalFloorAreaElement = $(element).find('.bukken-land-area-unit-prices-total-floor-area')[0];
    let tsuboUnitElement = $(element).find('.bukken-land-area-unit-prices-tsubo-unit')[0];

    landPriceElement.addEventListener('change', function() {
      SaleBukken.calcTotalAmount(landPriceElement, buildingExteriorTaxElement, totalAmountElement);
    });
    buildingExteriorTaxElement.addEventListener('change', function() {
      SaleBukken.calcTotalAmount(landPriceElement, buildingExteriorTaxElement, totalAmountElement);
    });

    totalFloorAreaElement.addEventListener('change', function() {
      SaleBukken.calcAreaTubo(totalFloorAreaElement, tsuboUnitElement);
    });
  },

  initleDisable: function(element, is_single) {
    let disabledElement = $(element).find('.bukken-land-area-unit-prices-land-disabled')[0];

    disabledElement.addEventListener('change', function() {
      if ($(this).is(":checked")) {
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-land-number').prop("readonly", true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-land-number').css('border', 'solid 1px #cccccc');
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-price').prop("readonly", true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-price').css('border', 'solid 1px #cccccc');
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-square-meter').prop("readonly", true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-square-meter').css('border', 'solid 1px #cccccc');
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-tubo').data('disabled', true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-price-per-tubo').data('disabled', true);
        if (is_single) return;
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-land-price').prop('readonly', true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-building-exterior-tax').prop('readonly', true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-total-amount').data('disabled', true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-total-floor-area').prop('readonly', true);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-tsubo-unit').data('disabled', true);
      } else {
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-land-number').prop("readonly", false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-price').prop("readonly", false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-square-meter').prop("readonly", false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-tubo').data('disabled', false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-price-per-tubo').data('disabled', false);
        if (is_single) return;
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-land-price').prop('readonly', false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-building-exterior-tax').prop('readonly', false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-total-amount').data('disabled', false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-total-floor-area').prop('readonly', false);
        $(this).closest('.nested-fields').find('input.bukken-land-area-unit-prices-tsubo-unit').data('disabled', false);
      }
    });
  },

  initInputEvent: function(element) {
    let id = $(element).find('input').filter(':first').attr('id').split('_')[4];
    for( let i = 1; i <= 5 ; i ++){
      let temp = $(element).find(`#bukken-land-area-pic-comment-input-${i}`)
      temp.data('counter', `span#bukken-land-area-pic-comment-counter-${i}-${id}`)
      temp.attr('id', `bukken-land-area-pic-comment-input-${i}-${id}`)
      $(element).find(`#bukken-land-area-pic-comment-counter-${i}`).attr('id', `bukken-land-area-pic-comment-counter-${i}-${id}`)
      $(element).find('a.input-button')[i-1].setAttribute('data-partition-index',id)

      if (i > 2) continue;
      $(element).find(`#label-section${i}`).attr({
        id: `label-section${i}-${id}`,
        for: `input-section${i}-${id}`
      });
      $(element).find(`#input-section${i}`).attr('id',`input-section${i}-${id}`);
    }
  },

  partitionElements: function(is_single) {
    if (is_single) {
      return {
        content: $('#bukken-land-area-unit-prices-content').find('.nested-fields:visible'),
        message: $('.custom-popup .error-message')
      }
    } else {
      return {
        content: $('#multiple-partitions-content').find('.nested-fields:visible'),
        message: $('#tab-multiple-partition .error-message')
      }
    }
  },

  initiateSortIcon: function () {
    $('.sortable-sales-bukken').sortable(
      {
        handle: '.sort-bukken-icon',
        cursor: 'move',
        scroll: false
      }
    )
  },

  updateBukkenOrder: function () {
    $(document).on('click', '.btn-update-sort-sale-bukken', function () {
      $(this).prop('disabled', true);
      let order = $('.sortable-sales-bukken').sortable(
        'toArray',{ attribute: 'data-sort-bukken' }
      ).join();

      const urlMapping = {
        '/kanri/sales/house': 'house/sort_sale_order',
        '/kanri/sales/tochi': 'tochi/sort_sale_tochi_order',
        '/kanri/sales/syueki': 'syueki/sort_sale_order',
        '/kanri/sales/fuk/tochi': 'fuk/tochi/sort_sale_tochi_order'
      };

      $.ajax({
        url: `/kanri/sales/${urlMapping[window.location.pathname]}`,
        data: { ids_change_order: order },
        method: 'POST',
        success: function(res) {
          $('.btn-update-sort-sale-bukken').prop('disabled', false);
          toastr.success('更新されました')
        },
        error: function (request, status, error) {
          $('.btn-update-sort-sale-bukken').prop('disabled', false);
          handleAjaxError(request, status, error);
        }
      })
    })
  },

  resetSort: function () {
    $('#sale_page_sort').on('hidden.bs.modal', function () {
      $(".sortable-sales-bukken").sortable("cancel")
    })
  },

  handleFactSheetType: function() {
    $('.factsheet-select').bind('change',function() {
      SaleBukken.factSheetTypesInit();
    });
  },

  factSheetTypesInit: function() {
    let typeList = []
    switch ($('#bukken_bukken_type').val()) {
      case '1': { // house
        if ($('#bukken_house_tab').val() == 'new_house') {
          typeList = [
            { id: 24, name: 'ファクト出力用/MAP図（450×400）'},
            { id: 45, name: 'ファクト出力用/MAP図（450×340）'}, // 新築のみ
            { id: 25, name: 'ファクト出力用/間取図（283×366）'},
            { id: 26, name: 'ファクト出力用/内外観1（400×400）'},
            { id: 27, name: 'ファクト出力用/内外観2（400×400）'},
            { id: 28, name: 'ファクト出力用/内外観3（400×400）'},
            { id: 43, name: 'ファクト出力用/内外観4（400×400）'},
            { id: 44, name: 'ファクト出力用/内外観5（400×400）'},
            { id: 29, name: 'ファクト出力用/外観戸建（333×293）'}
          ]
        } else {
          typeList = [
            { id: 24, name: 'ファクト出力用/MAP図（450×400）'},
            { id: 25, name: 'ファクト出力用/間取図（283×366）'},
            { id: 26, name: 'ファクト出力用/内外観1（400×400）'},
            { id: 27, name: 'ファクト出力用/内外観2（400×400）'},
            { id: 28, name: 'ファクト出力用/内外観3（400×400）'},
            { id: 43, name: 'ファクト出力用/内外観4（400×400）'},
            { id: 44, name: 'ファクト出力用/内外観5（400×400）'},
            { id: 29, name: 'ファクト出力用/外観戸建（333×293）'}
          ]
        }
        break;
      }
      case '2': { // mansion
        typeList = [
          { id: 30, name: 'ファクト出力用/MAP図（450×400）'},
          { id: 31, name: 'ファクト出力用/間取図（縦長：223×361、横長：208×337）'},
          { id: 32, name: 'ファクト出力用/内外観1（400×400）'},
          { id: 33, name: 'ファクト出力用/内外観2（400×400）'},
          { id: 34, name: 'ファクト出力用/内外観3（400×400）'},
          { id: 35, name: 'ファクト出力用/内外観4（400×400）'},
          { id: 36, name: 'ファクト出力用/内外観5（400×400）'},
          { id: 37, name: 'ファクト出力用/外観マンション/縦長用（286×363）'},
          { id: 38, name: 'ファクト出力用/外観マンション/横長用（386×294）'},
        ]
        break;
      }
      case '3': { // tochi
        typeList = [
          { id: 39, name: 'ファクト出力用/MAP図（450×400）' },
          { id: 40, name: 'ファクト出力用/区画図（550x450）' },
          { id: 41, name: 'ファクト出力用/外観1（465x297）' },
          { id: 42, name: 'ファクト出力用/外観2（465x297）' },
        ]
      }
    }

    let selectElements = $('.factsheet-select');
    let selectedTypes = []
    selectElements.each(function(_, obj) {
      if ($(obj).val()) {
        selectedTypes.push(parseInt($(obj).val()));
      }
    });
    let availableTypes = typeList.filter(type => !selectedTypes.includes(type.id));

    selectElements.each(function(_, obj) {
      if ($(obj).val()) {
        var tempAvailableTypes = availableTypes.concat(typeList.find(type => type.id == $(obj).val())).sort((a, b) => { return a.id - b.id });
        var currentValue = parseInt($(obj).val());
      } else {
        var tempAvailableTypes = availableTypes
        var currentValue = null;
      }
      $(obj).html(null);
      $(obj).append(`<option value>ファクト用種別を選択してください</option>`);
      for (let type of tempAvailableTypes) {
        $(obj).append(`<option value=${type.id} ${type.id == currentValue ? 'selected' : ''} >${type.name}</option>`);
      }
    });
  },

  initPartitionSortable: function() {
    $('#partition_sortable').sortable({
      placeholder: 'ui-state-highlight',
      revert: (true, 100),
      scroll: true,
      items: 'tr.sortable'
    });
  },

  savePartitionOrder: function() {
    $('#save_partition_order').bind('click', function (e) {
      $('#save_partition_order').prop('disabled', true)
      $('#save_partition_order').addClass('wait');
      let id = $('#pic_bukken_id').val();
      let ids_change_order = $('#partition_sortable').sortable('toArray', { attribute: 'data-id' });
      $.ajax({
        url: '/kanri/sales/tochi/change_partition_order',
        data: { 'id': id, 'ids_change_order': ids_change_order },
        method: 'POST',
        success: function (response) {
          if (response.result) {
            window.location.href = response.url;
          } else {
            $('#save_partition_order').prop('disabled', false);
          }
        },
        complete: function(request, status) {
          $('#save_partition_order').removeClass('wait');
        }
      });
    });
  },
}

export default SaleBukken;
