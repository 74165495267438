
var SearchMap = {
  init: function() {
    SearchMap.initMap();
    SearchMap.applyMapData();
  },

  component_address: {
    sublocality_level_2: '',
    sublocality_level_3: '',
  },
  key: 'AIzaSyCeuvom3WdL4D6By9JZMo3O_HGBp_lTcJ8',

  initMap: function(){
    if($('#searchMap').length > 0){
      if(typeof window.google == 'undefined' || typeof google.maps == 'undefined'){
        var map_url_obj = { key: SearchMap.key, libraries: 'places', region:'JP', language: 'ja', v: '3' }
        var map_url = 'https://maps.googleapis.com/maps/api/js?' + $.param(map_url_obj)
        $.getScript(map_url, function(){
          SearchMap.initAutocomplete()
        });
      }
      else{
        SearchMap.initAutocomplete()
      }
    }
  },

  initAutocomplete: function(){
    let address = $('.map-address');
    let latitude = $('.map-lat');
    let longitude = $('.map-lng');
    let markers = [];

    let lat = 33.233087;
    let lng = 131.606501;
    if(latitude.val().trim().length > 0 && longitude.val().trim().length > 0){
      lat = parseFloat(latitude.val());
      lng = parseFloat(longitude.val());
    }
    const latlng = { lat: lat, lng: lng };
    const map = new google.maps.Map(document.getElementById("google-map"), {
      center: latlng,
      zoom: 13,
      streetViewControl: false,
      mapTypeId: "roadmap",
    });

    markers.push(
      new google.maps.Marker({
        position: latlng,
        map
      })
    )

    $('.find_address').on('click',function(){
      let q = $('.map-address').val();
      if(q){
        $.ajax({
          url: 'https://maps.googleapis.com/maps/api/geocode/json',
          data: { address: q, key: SearchMap.key, language: 'ja' },
          method: 'GET',
          success: function(response) {
            var geometry = response.results[0].geometry;
            var address_components = response.results[0].address_components
            SearchMap.set_component_address(address_components);

            const bounds = new google.maps.LatLngBounds();
            if(geometry){
              $('.map-lat').val(geometry.location.lat)
              $('.map-lng').val(geometry.location.lng)
              markers.forEach((marker) => {
                marker.setMap(null);
              });
              let latlng = new google.maps.LatLng(geometry.location.lat, geometry.location.lng)
              markers.push(
                new google.maps.Marker({
                  map,
                  position: latlng
                })
              );
              map.fitBounds(new google.maps.LatLngBounds(geometry.viewport.southwest, geometry.viewport.northeast));
            }
          },
        });
      }
    });

    $(".map-address").keydown(function(e){
      if(e.which === 13){
        $(".find_address").click();
      }
    });

    var geocoder = new google.maps.Geocoder();
    map.addListener("click", (mapsMouseEvent) => {
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];
      geocoder.geocode({
        'latLng': mapsMouseEvent.latLng
      }, function(results, status) {
        SearchMap.component_address.sublocality_level_2 = ''
        SearchMap.component_address.sublocality_level_3 = ''
        if (status == google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            var address_components = results[0].address_components
            SearchMap.set_component_address(address_components);

            var addr = results[0].formatted_address
            addr = addr.replace(/[^\p{L}\p{N}\p{P}\p{Z}]/gu, '-').replace('日本、-', '');
            address.val(addr);
            latitude.val(mapsMouseEvent.latLng.lat());
            longitude.val(mapsMouseEvent.latLng.lng());
          }
        }
      })

      markers.push(
        new google.maps.Marker({
          map,
          position: mapsMouseEvent.latLng
        })
      )
    });
  },

  set_component_address: function (address_components){
    for (var i = 0; i < address_components.length; i++) {
      var address_type = address_components[i].types[2];
      if (SearchMap.component_address[address_type] != undefined) {
        SearchMap.component_address[address_type] = address_components[i].short_name
      }
    }
  },

  applyMapData: function () {
    $('#apply_map_data').on('click', function () {
      if($('.map-address').val().trim().length > 0) {
        $('.google-address').val($('.map-address').val());
      }
      if($('.map-lat').val().trim().length > 0) {
        $('.google-latitude').val($('.map-lat').val());
      }
      if($('.map-lng').val().trim().length > 0){
        $('.google-longitude').val($('.map-lng').val());
      }

      $('#searchMap').modal('toggle');

      // TODO 福岡物件に「町（自治区）名」追加後、search_towns を実行できるようにする
      const path = location.pathname
      if (!path.match(/^\/kanri\/sales\/fuk/)) {
        // Filter list towns by town_name
        SearchMap.search_towns();
      } else {
        SearchMap.search_fuk_towns();
      }
    });
  },

  search_towns: function () {
    const option_default = "<option value=\"\">--</option>"
    $('.modal-loading').modal('show')
    $.ajax({
      url: '/kanri/sales/house/search_towns',
      data: { town_name: SearchMap.component_address.sublocality_level_2 + SearchMap.component_address.sublocality_level_3 },
      method: 'get',
      success: function (response) {
        $('.modal-loading').modal('hide')
        $('#BukkenJuniorHighSchoolInput').html(option_default);
        $('#BukkenPrimarySchoolInput').html(option_default);
        $('#bukken_primary_school_dist').val('');
        $('#bukken_junior_high_dist').val('');
      },
    });
  },

  search_fuk_towns: function () {
    const option_default = "<option value=\"\">--</option>"
    $('.modal-loading').modal('show')
    $.ajax({
      url: '/kanri/sales/fuk/house/search_towns_by_name',
      data: { city_name: $('[id=FukuokaBukkenAddressCityInput] option:selected').text(),
              town_name: SearchMap.component_address.sublocality_level_2 + SearchMap.component_address.sublocality_level_3 },
      method: 'get',
      success: function (response) {
        $('.modal-loading').modal('hide')
        $('#FukuokaBukkenJuniorHighSchoolInput').html(option_default);
        $('#FukuokBukkenPrimarySchoolInput').html(option_default);
        $('#Fukuokbukken_primary_school_dist').val('');
        $('#Fukuokbukken_junior_high_dist').val('');
      },
    });
  },
}

export default SearchMap;
